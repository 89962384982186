const recaptcha = window.grecaptcha;

class reCAPTCHA {
	constructor(container) {
		this.container = container;
		this.el = container.querySelector('.recaptcha');
		this.id = null;
	}

	async init() {
		if (this.isInit()) {
			this.reset();
		} else {
			await new Promise(resolve => {
				recaptcha.ready(() => {
					this.el.classList.add('recaptcha-required');

					this.id = recaptcha.render(this.el, {
						sitekey: window.config.recaptcha.v2,
					});

					resolve();
				});
			});
		}
	}

	isInit() {
		return this.id !== null;
	}

	isRequired() {
		if (!this.el) {
			return false;
		}
		
		return this.el.matches('.recaptcha-required');
	}

	reset() {
		recaptcha.reset(this.id);
	}

	value() {
		return recaptcha.getResponse(this.id);
	}

	handleResponse(res) {
		if (!window.config.recaptcha.enabled) {
			return;
		}

		if (res.hasOwnProperty('needsVerification')) {
			this.init();
		} else {
			this.reset();
		}
	}

	isValid() {
		return !!this.value();
	}

	static async addToken(data) {
		const token = await this.execute('submit');

		data['v3-response'] = token;
	}

	static execute(action = 'submit') {
		return new Promise((resolve, reject) => {
			grecaptcha.ready(async function() {
				const token = await grecaptcha.execute(window.config.recaptcha.v3, { action });

				resolve(token);
			});
		});
	}
}

export default reCAPTCHA;
